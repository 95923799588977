<template>
  <div>
    <RoleCard :role="role">
      <template #label>{{
        role === "student" ? "Студент" : "Школьник (5−11 класс)"
      }}</template>
      <template #label_info
        >Шаг 2&nbsp;из&nbsp;3&nbsp;&mdash; заполнение данных</template
      >
      <template #label_img>
        <img :src="iconPath" />
      </template>
      <template #body>
        <div v-if="role === 'school'">
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <div>
                Этот трек только для учащихся 5&minus;11 классов
                общеобразовательных школ. Если вы&nbsp;учитесь в&nbsp;колледже,
                техникуме или другой организации СПО, приглашаем вас
                на&nbsp;<router-link
                  :to="{
                    name: 'registration-student',
                    query: $route.query,
                  }"
                  class="link link--pseudo"
                  >студенческий трек НТО</router-link
                >.
              </div>
            </div>
          </div>
          <hr class="hr" />
        </div>
        <UserForm
          :talent-user="talentUser"
          :role="role"
          :schedule="schedule"></UserForm>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import UserForm from "@/components/registration/UserForm.vue";
import RoleCard from "@/components/registration/RoleCard.vue";
import store from "@/store";
import { request } from "@/services/api";
import { REGISTRATION_STAGE } from "@/constants";
export default {
  name: "RegistrationParticipantPage",
  components: {
    UserForm,
    RoleCard,
  },
  metaInfo() {
    console.log("this.$route.name", this.$route.name);
    let title = "Регистрация для школьников 5-11 классов";
    if (this.$route?.name === "registration-student") {
      title = "Регистрация для студентов";
    }
    return {
      title,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const talentUser = store.state.user.talentUser;
    if (!talentUser) {
      return next({
        name: "registration-login",
        query: {
          next: to.fullPath,
        },
      });
    }
    next();
  },
  data() {
    return {
      schedule: [],
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    role() {
      return this.$route.name === "registration-student" ? "student" : "school";
    },
    iconPath() {
      return require(`@/assets/images/reg_${this.role}.svg`);
    },
  },
  mounted() {
    this.getRegistrationSchedule();
  },
  methods: {
    async getRegistrationSchedule() {
      try {
        const { data } = await request({
          method: "GET",
          url: "/schedule",
          params: {
            stage: REGISTRATION_STAGE,
            size: 50,
          },
        });
        this.schedule = data.items?.filter((n) => {
          return n.stage === REGISTRATION_STAGE && !n.profile;
        });
      } catch (error) {
        // do nothing
      }
    },
  },
};
</script>
